body {
    font-family: $font, sans-serif;
    overflow-x: hidden;
    color: #252525;
}
h1, h2, h3, p{
    margin: 0;
}

h1, h2, h3, h4, h5, button {
    font-family: $mont-font, sans-serif !important;
}

p {
    font-family: $inter-font, sans-serif !important;
}

ol, ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
blockquote, q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
hr{
    border-color: $grey;
}
html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a, a:active, a:hover, a:focus{
    text-decoration: none;
}
label{
    margin-bottom: 0;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes navbar-animate {
    0% {top: -80px;}
    100% {top: 0;}
}

@keyframes navbar-animate-back {
    0% {top: 0;}
    100% {top: -80px;}
}


.errors-container, .success-container{
    display: none;
    margin-bottom: 10px;
}
.invalid-feedback, .success-feedback{
    font-size: 16px;
    font-family: $font, sans-serif;;
    display: inline-block;
    width: 100%;
}
.success-feedback{
    color: green;
}

.image-shadow {
    box-shadow: 0 10px 30px 0 rgba(131, 144, 172, 0.18);
}

.text-bg-gradient {
    background: linear-gradient(to bottom, rgba(0,0,0,0), #000);
}

.loading{
    &:before{
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #ffffffa6;
        position: absolute;
        border-radius: 8px;
        z-index: 1;
    }
    &:after{
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
        border: 8px solid #000000;
        border-radius: 50%;
        border-top: 8px solid black;
        border-bottom: 8px solid #000000;
        width: 50px;
        height: 50px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    &-no-opacity{
        &:before{
            background-color: #ffffff;
        }
    }
}

*{
    &::-webkit-scrollbar-thumb {
        background: #000000;
        border-radius: 4px;
    }
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-ms-scrollbar-thumb {
        background: #000000;
        border-radius: 4px;
    }
    &::-ms-scrollbar {
        width: 4px;
    }
    &::-moz-scrollbar-thumb {
        background: #000000;
        border-radius: 4px;
    }
    &::-moz-scrollbar {
        width: 4px;
    }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}