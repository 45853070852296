.slick-dots {
    text-align: left;
    li {
        width: auto;
        button {
            width: 12px;
            height: 12px;
            background: #8390ac
            ;
            border-radius: 12px;
            opacity: 0.43;
            &:before {
                content: '';
            }
        }
        &.slick-active {
            margin: 0 10px;
            button {
                opacity: 1;
                background-color: #192848;
            }
        }
    }
}

#trust {
    .slider {
        .slick-dots {
            li {
                &.slick-active {
                    
                }
            }
        }
    }
}