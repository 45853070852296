.svg-icon {
    display: block;
    background-position: center;
    background-size: cover;
    width: 32px;
    height: 32px;
    &.icon-sm {
        width: 18px;
        height: 18px;
    }
    &.ic-facebook {
        background-image: url('../img/icons/ic-facebook.svg');
    }
    &.ic-instagram {
        background-image: url('../img/icons/ic-instagram.svg');
    }
    &.ic-linkedin {
        background-image: url('../img/icons/ic-linkedin.svg');
    }
    &.ic-navigation {
        background-image: url('../img/icons/ic-navigation.svg');
    }

}

.ic-menu {
    display: block;
    background-position: center;
    background-size: cover;
    width: 24px;
    height: 24px;
    background-image: url('../img/icons/ic-menu.svg');
}


.ic-plus {
    display:block;
    background-position: center;
    background-size: cover;
    width: 24px;
    height: 24px;
    background-image: url('../img/icons/ic-plus.svg');
}

.ic-sound-on {
    display:block;
    background-position: center;
    background-size: cover;
    width: 24px;
    height: 24px;
    background-image: url('../img/icons/ic-sound-on.png');
}

.ic-sound-off {
    display:block;
    background-position: center;
    background-size: cover;
    width: 24px;
    height: 24px;
    background-image: url('../img/icons/ic-sound-off.png');
}


.ic-play {
    display:block;
    background-position: center;
    background-size: cover;
    width: 32px;
    height: 32px;
    background-image: url('../img/icons/ic-play.png');
}

.ic-pause {
    display:block;
    background-position: center;
    background-size: cover;
    width: 32px;
    height: 32px;
    background-image: url('../img/icons/ic-pause.png');
}

