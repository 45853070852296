.contact-wrapper {
    img {
        width: 100%;
        height: 100%;
    }
}
.contact-form-container {
    padding: 32px 16px;
    @media screen and (min-width:1024px) {
        padding: 90px 101px 91px 106px;    
    }
}