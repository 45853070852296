.group-banner-wrapper {
        #background-video {
        width: 100vw;
        height: 50vh;
        object-fit: cover;
        position: relative;
        z-index: -1;
        margin-top: -112px;
        }
        p {
                @media screen and (max-width: 1024px) {
                        font-size:50px !important;
                }

                @media screen and (max-width: 550px) {
                        font-size:35px !important;
                }

                @media screen and (max-width: 375px) {
                        font-size:30px !important;
                }
        }
}

.team-group-wrapper {
        padding: 48px 0;
        @media screen and (min-width: 1024px) {
                padding: 110px 0;
        }
        #bt1, #bt2 {
                @media screen and (max-width: 1024px) {
                        border-bottom: solid 1px lightgray !important;
                }
        }
}

.map-wrapper {
        position:relative;
        width: 100%;
        contain: content;
        height: calc(100vw * 1.1444);
        @media screen and (min-width:1024px) {
                height: 661px;
        }
        #mapImg {
                position: absolute;
                @media screen and (min-width:1024px) {
                        height: 761px;
                }
                height: calc(100vw * 1.1444);
        }
        .background-map-desktop {
                display: none;
                @media screen and (min-width:1024px) {
                        display: block;
                        width: 100%;
                        object-fit: cover;
                        z-index: -1;
                }
        }
        .background-map-mobile {
                display: block;
                width: 100%;
                z-index: -1;
                @media screen and (min-width:1024px) {
                        display: none;
                }
        }
        .countries {
                & > * {
                        margin: 0;
                        font-family: Montserrat;
                        font-size: 18px;
                        font-weight: 600;
                        color: #fff;
                        text-transform: uppercase;
                        line-height: 22px;
                }
                :first-child {
                        margin-top: 40px;
                }
        }
        .countries-wrapper {
                display: none;
                @media screen and (min-width:1024px) {
                        display: block;
                }
        }
}

.checkout-banner {
        padding: 48px 0;
        @media screen and (min-width:1024px) {
                padding: 110px 0;
        }
       position: relative;
        &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                background-image: url('../img/bg-shape@2x.jpg');
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
                z-index: -1;
        }
        .container {
                max-width: 95% !important;
                li {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        color: #192848;
                        margin-bottom: 16px;
                        
                        &:before {
                            content: '';
                            display: block;
                            margin-right: 5px;
                            width: 16px;
                            height: 16px;
                            min-width: 16px;
                            background-image: url('../img/icons/ic-large-dot-primary.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                            padding-right: 0px;
                        }
                }
                .con {
                        width: 32% !important;
                        @media screen and (max-width: 1024px) {
                                margin: 0 auto;
                                width: 80% !important;
                        }

                        @media screen and (max-width: 768px) {
                                width: 100% !important;
                        }

                }
        }
}

.group-card-image {
        min-height: 102px;
        max-height: 102px;
}

.map-chip {
        border: 1px solid #ffffff80;
        &.active {
                border: 1px solid $primary;
                background-color: $primary;
        }
}