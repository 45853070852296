$enable-grid-classes: true;
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-row-columns: 6;

$container-max-widths: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
	) !default;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
	) !default;

// Colors
$white: #fff;
$blue: #116493;
$light-blue: #02ACDE;
$dark-blue: #192848;
$red: #EB3420;
$grey: #8390ac;

$primary: $light-blue;
$primary-variant: $blue;
$secondary: $dark-blue;
$secondary-variant: $grey;
$error: $red;

// Fonts
$font: 'Open Sans';
$secondary-font: 'ITC Kabel Std Demi';
$mont-font: 'Montserrat';
$inter-font: 'Inter';

// Font Icons
$icomoon-font-path: "/fonts/icons" !default;
$icomoon-font-family: "icons" !default;

$icon-menu: "\e917";
$icon-close: "\e916";
$icon-carret-up: "\e900";
$icon-carret-down: "\e910";