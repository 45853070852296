.menu-modal {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(25, 40, 72,0.4);
}



.menu-modal-content {
    display: flex;
    justify-content: center;
    background-color: #fefefe;
    width: 100%;/* Could be more or less, depending on screen size */
    height: max-content;
    padding-top: 20px;
    .con {
        width: 95%;
        img {
            width: 137px;
            height: 40px;
        }
        .mobile-menu {
            ul > li {
                margin-left: 10px;
                margin-bottom: 30px;
            }
            .py-6 {
                .img-toolbox {
                    width:106px;
                    height:17px;
                }
                .img-nomad {
                    width:74px;
                    height:20px;
                }
                div {
                    margin-left: 10px;
                    margin-top: 25px;
                }
            }
        }
    }
}