header {
    position: relative;
    z-index: 999;
    padding-top: 20px;
    transition: all 0.2s;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1170px;
        width: 95%;
        padding: 0 !important;
        .site-logo {
            img {
                transition: all 0.2s;
                width: 180px;
            }
        }
        .site-menus {
            width: calc(100% - 150px);
            .menu-section {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                div {
                    margin-left: 3%;
                    font-size: 14px;
                    font-weight: bold;
                    color: white;
                    @media screen and (max-width:1100px) {
                        margin-left: 2%;
                    }
                }
                .submenu {
                    ul {
                        box-shadow: 0 10px 30px 0 rgb(131 144 172 / 18%);
                    }
                }
                .maroc{
                    margin:0;
                }
            }
        }
    }
}

.active-menu {
    font-weight: 900 !important;
}

.fixed {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white !important;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0), 0 3px 5px -1px rgba(0, 0, 0, 0.06);
    height: 95px !important;
    padding-top: 0px !important;
    .container {
        .site-menus {
            .menu-section {
                div {
                    color: #192848 !important;
                }
            }
        }
    }
}

#toolbox, #occasion, #contact, #stock {
    header {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white !important;
        box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0), 0 3px 5px -1px rgba(0, 0, 0, 0.06);
        height: 80px !important;
        padding-top: 0px !important;
        .container {
            .site-menus {
                .menu-section {
                    div {
                        color: #192848 !important;
                    }
                }
            }
        }
    }
}

.mobile-header-overlay {
	position: fixed;
	z-index: 11;
	width: 100vh;
	top: 0;
	height: 100vh;
	background: $white;
	left: 0;
	display: none;
}



