.about-banner {
    #background-video {
        width: 100vw;
        height: 50vh;
        object-fit: cover;
        position: relative;
        z-index: -1;
        margin-top: -112px;
    }
    p {
        @media screen and (max-width: 400px) {
            font-size:30px !important;
        }
    }
}

.video-wrapper {
    position: relative;
    .video-control {
        position: absolute;
        left: calc(50% - 24px);
        top: calc(50% - 24px);
        padding: 16px;
        background: $primary;
        border-radius: 50%;
    }
    #bottom-video {
        width: 100vw;
        object-fit: cover;
    }
}

.about-intro-wrapper {
    img {
        width: 100%;
        height: 260px;
        object-fit: cover;
        @media screen and (min-width: 1024px) {
            width: 100%;
            height: 100%;    
        }
    }
}

.skills-wrapper {
    position: relative;
    background-image: url('../img/bg-grey@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 48px 16px;

    @media screen and (min-width:1024px) {
        padding: 110px 99px;
    }
}

.img-wrapper {
    contain: content;
    height:440px;

    img {
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.team-slider-wrapper {
    padding: 48px 0;
    position: relative;
    background: white;
    @media screen and (min-width: 1024px) {
        padding: 110px 100px 70px 100px;    
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('../img/bg-shape@2x.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        z-index: -1;
    }
    .team-slider-phone-icon {
        transition: all 0.2s;
        &:before {
            content: '';
            width: 18px;
            height: 18px;
            background-image: url('../../public/images/statics/icons/ic-phone.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }
        &:hover {
            &:before {
                background-image: url('../../public/images/statics/icons/ic-phone-white.svg');
            }
        }
    }
    .slider {
        img {
            width: 100%;
            aspect-ratio: 1/1.3;    
        }
        .team-slider-lay {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, 0.65));
            box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.14);
            border-radius: 4px;
            contain: content;
        }
        .slick-dots {
            width: 80%;
            display: flex;
            padding-left: 1rem;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 0;
            margin-left: 1rem;
            padding: 0px 0px;
            list-style-type: none;
            bottom: -65px;
            left: 0px;
            li {
                margin: 0 !important;
            }
                button {
                    width: 100%;
                    display: block;
                    height: 3px;
                    padding: 1.5px 0 !important;
                    border-radius: 1.5px;
                    background-color: #8390ac;
                }
            
                li.slick-active button {
                    margin: 0 !important;
                    background-color: #192848;
                }
        }
    }
    .slider-mobile {
        .team-slider-lay {
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, 0.65));
            box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.14);
            contain: content;
            .team-slider-phone-icon {
                &:before {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
    .img-prev {
        display: block;
        padding:12px;
        border: solid 1px rgba(131, 144, 172, 0.5);
        width: 48px;
        height: 48px;
        background: url('../img/icons/ic-arrow-left.svg');
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        cursor: pointer;
    }
    .img-next {
        display: block;
        padding:12px;
        border: solid 1px rgba(131, 144, 172, 0.5);
        width: 48px;
        height: 48px;
        background: url('../img/icons/ic-arrow-right.svg');
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        cursor: pointer;
    }
}

