@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
    font-family: 'ITC Kabel Std Book';
    font-style: normal;
    font-weight: normal;
    src: local('ITC Kabel Std Book'), url('/fonts/ITCKabelStd/ITCKabelStdBook.woff') format('woff');
}
    

@font-face {
    font-family: 'ITC Kabel Std Medium';
    font-style: normal;
    font-weight: normal;
    src: local('ITC Kabel Std Medium'), url('/fonts/ITCKabelStd/ITCKabelStdMedium.woff') format('woff');
}
    

@font-face {
    font-family: 'ITC Kabel Std Demi';
    font-style: normal;
    font-weight: normal;
    src: local('ITC Kabel Std Demi'), url('/fonts/ITCKabelStd/ITCKabelStdDemi.woff') format('woff');
}
    

@font-face {
    font-family: 'ITC Kabel Std Bold';
    font-style: normal;
    font-weight: normal;
    src: local('ITC Kabel Std Bold'), url('/fonts/ITCKabelStd/ITCKabelStdBold.woff') format('woff');
}
    

@font-face {
    font-family: 'ITC Kabel Std Ultra';
    font-style: normal;
    font-weight: normal;
    src: local('ITC Kabel Std Ultra'), url('/fonts/ITCKabelStd/ITCKabelStdUltra.woff') format('woff');
}

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xvt586');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xvt586#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xvt586') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xvt586') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xvt586##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-size: 24px;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
