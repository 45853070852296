@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  color: #252525;
}

h1, h2, h3, p {
  margin: 0;
}

h1, h2, h3, h4, h5, button {
  font-family: "Montserrat", sans-serif !important;
}

p {
  font-family: "Inter", sans-serif !important;
}

ol, ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

hr {
  border-color: #8390ac;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, a:active, a:hover, a:focus {
  text-decoration: none;
}

label {
  margin-bottom: 0;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes navbar-animate {
  0% {
    top: -80px;
  }
  100% {
    top: 0;
  }
}
@keyframes navbar-animate-back {
  0% {
    top: 0;
  }
  100% {
    top: -80px;
  }
}
.errors-container, .success-container {
  display: none;
  margin-bottom: 10px;
}

.invalid-feedback, .success-feedback {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  width: 100%;
}

.success-feedback {
  color: green;
}

.image-shadow {
  box-shadow: 0 10px 30px 0 rgba(131, 144, 172, 0.18);
}

.text-bg-gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
}

.loading:before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ffffffa6;
  position: absolute;
  border-radius: 8px;
  z-index: 1;
}
.loading:after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  border: 8px solid #000000;
  border-radius: 50%;
  border-top: 8px solid black;
  border-bottom: 8px solid #000000;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.loading-no-opacity:before {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 4px;
}
*::-webkit-scrollbar {
  width: 4px;
}
*::-ms-scrollbar-thumb {
  background: #000000;
  border-radius: 4px;
}
*::-ms-scrollbar {
  width: 4px;
}
*::-moz-scrollbar-thumb {
  background: #000000;
  border-radius: 4px;
}
*::-moz-scrollbar {
  width: 4px;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@font-face {
  font-family: "ITC Kabel Std Book";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Kabel Std Book"), url("/fonts/ITCKabelStd/ITCKabelStdBook.woff") format("woff");
}
@font-face {
  font-family: "ITC Kabel Std Medium";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Kabel Std Medium"), url("/fonts/ITCKabelStd/ITCKabelStdMedium.woff") format("woff");
}
@font-face {
  font-family: "ITC Kabel Std Demi";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Kabel Std Demi"), url("/fonts/ITCKabelStd/ITCKabelStdDemi.woff") format("woff");
}
@font-face {
  font-family: "ITC Kabel Std Bold";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Kabel Std Bold"), url("/fonts/ITCKabelStd/ITCKabelStdBold.woff") format("woff");
}
@font-face {
  font-family: "ITC Kabel Std Ultra";
  font-style: normal;
  font-weight: normal;
  src: local("ITC Kabel Std Ultra"), url("/fonts/ITCKabelStd/ITCKabelStdUltra.woff") format("woff");
}
@font-face {
  font-family: "icons";
  src: url("/fonts/icons/icons.eot?xvt586");
  src: url("/fonts/icons/icons.eot?xvt586#iefix") format("embedded-opentype"), url("/fonts/icons/icons.ttf?xvt586") format("truetype"), url("/fonts/icons/icons.woff?xvt586") format("woff"), url("/fonts/icons/icons.svg?xvt586#icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: never;
  font-style: normal;
  font-size: 24px;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.svg-icon {
  display: block;
  background-position: center;
  background-size: cover;
  width: 32px;
  height: 32px;
}
.svg-icon.icon-sm {
  width: 18px;
  height: 18px;
}
.svg-icon.ic-facebook {
  background-image: url("../img/icons/ic-facebook.svg");
}
.svg-icon.ic-instagram {
  background-image: url("../img/icons/ic-instagram.svg");
}
.svg-icon.ic-linkedin {
  background-image: url("../img/icons/ic-linkedin.svg");
}
.svg-icon.ic-navigation {
  background-image: url("../img/icons/ic-navigation.svg");
}

.ic-menu {
  display: block;
  background-position: center;
  background-size: cover;
  width: 24px;
  height: 24px;
  background-image: url("../img/icons/ic-menu.svg");
}

.ic-plus {
  display: block;
  background-position: center;
  background-size: cover;
  width: 24px;
  height: 24px;
  background-image: url("../img/icons/ic-plus.svg");
}

.ic-sound-on {
  display: block;
  background-position: center;
  background-size: cover;
  width: 24px;
  height: 24px;
  background-image: url("../img/icons/ic-sound-on.png");
}

.ic-sound-off {
  display: block;
  background-position: center;
  background-size: cover;
  width: 24px;
  height: 24px;
  background-image: url("../img/icons/ic-sound-off.png");
}

.ic-play {
  display: block;
  background-position: center;
  background-size: cover;
  width: 32px;
  height: 32px;
  background-image: url("../img/icons/ic-play.png");
}

.ic-pause {
  display: block;
  background-position: center;
  background-size: cover;
  width: 32px;
  height: 32px;
  background-image: url("../img/icons/ic-pause.png");
}

.v-align-solo {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.clamped {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.outline-none {
  outline: none;
}
.outline-none:focus, .outline-none:active {
  outline: none;
}

.no-lh {
  line-height: normal !important;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;
}

.modal {
  position: fixed;
  background-color: #ffffff;
  overflow: auto;
  width: 360px;
  left: 50%;
  height: auto;
  top: 50%;
  display: none;
  margin-left: -200px;
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 rgba(0, 27, 56, 0.4);
}
.modal .modal-close {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  right: 24px;
  border-radius: 20px;
  top: 24px;
  width: 38px;
  font-size: 14px;
  text-align: center;
  line-height: 36px;
  color: grey;
  border: 1px solid #d9d9d9;
}
.modal .modal-container {
  padding-top: 88px;
}
.modal .modal-container h3, .modal .modal-container h4 {
  color: #000000;
  font-size: 30px;
  font-family: "Nunito", sans-serif;
}
.modal .modal-container .content-modal {
  padding: 0 24px;
  position: relative;
}
.modal .modal-floated-content {
  max-height: calc(100vh - 88px - 100px);
  overflow: auto;
}
.modal-no-padding * {
  padding: 0 !important;
  line-height: 0;
}
.modal-visible {
  overflow: visible;
}
@media (max-width: 767.98px) {
  .modal {
    display: none;
    width: 100% !important;
    min-width: 0 !important;
    left: 0 !important;
    top: 0 !important;
    position: fixed !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    height: 100vh !important;
    z-index: 11 !important;
    overflow: auto !important;
  }
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  background-color: #000000;
  display: none;
  z-index: 10;
}

.trigger-modal {
  cursor: pointer;
}

.slick-dots {
  text-align: left;
}
.slick-dots li {
  width: auto;
}
.slick-dots li button {
  width: 12px;
  height: 12px;
  background: #8390ac;
  border-radius: 12px;
  opacity: 0.43;
}
.slick-dots li button:before {
  content: "";
}
.slick-dots li.slick-active {
  margin: 0 10px;
}
.slick-dots li.slick-active button {
  opacity: 1;
  background-color: #192848;
}

.dropdown-wrapper {
  display: inline-block;
  position: relative;
}

.dropdown {
  top: 100%;
  padding: 16px;
  border-radius: 4px;
  z-index: 1050;
  line-height: 24px;
  background: white;
  position: absolute;
  color: #000000;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  min-width: 100%;
}
.dropdown a {
  color: #000000;
}
.dropdown-right-anchor {
  right: 0;
}
.dropdown-top-anchor {
  top: auto;
  bottom: 100%;
}
.dropdown-fixed {
  min-width: initial;
}
@media (max-width: 767.98px) {
  .dropdown {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100vh;
    margin-top: 0;
    border-radius: 0;
  }
}
.dropdown li {
  cursor: pointer;
}

header {
  position: relative;
  z-index: 999;
  padding-top: 20px;
  transition: all 0.2s;
}
header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1170px;
  width: 95%;
  padding: 0 !important;
}
header .container .site-logo img {
  transition: all 0.2s;
  width: 180px;
}
header .container .site-menus {
  width: calc(100% - 150px);
}
header .container .site-menus .menu-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
header .container .site-menus .menu-section div {
  margin-left: 3%;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
@media screen and (max-width: 1100px) {
  header .container .site-menus .menu-section div {
    margin-left: 2%;
  }
}
header .container .site-menus .menu-section .submenu ul {
  box-shadow: 0 10px 30px 0 rgba(131, 144, 172, 0.18);
}
header .container .site-menus .menu-section .maroc {
  margin: 0;
}

.active-menu {
  font-weight: 900 !important;
}

.fixed {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0), 0 3px 5px -1px rgba(0, 0, 0, 0.06);
  height: 95px !important;
  padding-top: 0px !important;
}
.fixed .container .site-menus .menu-section div {
  color: #192848 !important;
}

#toolbox header, #occasion header, #contact header, #stock header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0), 0 3px 5px -1px rgba(0, 0, 0, 0.06);
  height: 80px !important;
  padding-top: 0px !important;
}
#toolbox header .container .site-menus .menu-section div, #occasion header .container .site-menus .menu-section div, #contact header .container .site-menus .menu-section div, #stock header .container .site-menus .menu-section div {
  color: #192848 !important;
}

.mobile-header-overlay {
  position: fixed;
  z-index: 11;
  width: 100vh;
  top: 0;
  height: 100vh;
  background: #fff;
  left: 0;
  display: none;
}

footer {
  padding-top: 64px;
  background-image: url("../img/bg-footer@2x.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.form-group {
  position: relative;
}

.form-control:focus {
  border: solid 2px #02acde !important;
}

.form-group > label {
  top: 0px;
  left: 20px;
  position: absolute;
  background-color: white;
  padding: 0px 5px 0px 5px;
  visibility: hidden;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
}

.form-group.label-animate > label {
  top: -10px !important;
  left: 20px !important;
  font-size: 0.9em;
  visibility: visible;
  color: #02acde;
}

input {
  width: 100%;
  height: 100%;
  border: solid 1px #8390ac;
  border-radius: 4px;
  padding: 12px 12px 12px 16px;
  outline: none;
}

textarea {
  width: 100%;
  height: 100%;
  border: solid 1px #8390ac;
  border-radius: 4px;
  padding: 12px 12px 12px 16px;
  outline: none;
}

textarea:focus {
  border: solid 2px #02acde;
}

.input-group {
  margin-bottom: 20px;
}

.work-modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(25, 40, 72, 0.4);
}
.work-modal .container {
  max-height: 100%;
}
@media screen and (max-width: 768px) {
  .work-modal .container {
    max-width: 100% !important;
    padding: 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .work-modal .container .swiper {
    overflow: inherit;
  }
}
.work-modal .container .swiper .swiper-slide img {
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .work-modal .container .swiper .swiper-slide img {
    height: 280px !important;
  }
}
.work-modal .container .swiper-button-prev, .work-modal .container .swiper-button-next {
  color: white;
}
.work-modal .container .swiper-button-next:after, .work-modal .container .swiper-button-prev:after {
  font-size: 20px;
  font-weight: bold;
}
.work-modal .container .swiper-pagination-bullet-active, .work-modal .container .swiper-pagination-bullet {
  opacity: 1;
  background: white !important;
}
.work-modal .container .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .work-modal .container .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 7px;
}
.work-modal .container .swiper-horizontal > .swiper-pagination-bullets, .work-modal .container .swiper-pagination-bullets.swiper-pagination-horizontal, .work-modal .container .swiper-pagination-custom, .work-modal .container .swiper-pagination-fraction {
  bottom: 20px;
}

.slider-wrapper {
  position: relative;
  height: inherit !important;
}
.slider-wrapper .slider {
  margin-bottom: 0px !important;
}
.slider-wrapper .slider img {
  height: 100% !important;
}
.slider-wrapper .slider .slick-dots {
  position: absolute;
  display: flex;
  bottom: 20px;
  align-items: center;
  justify-content: center;
}
.slider-wrapper .slider .slick-dots li {
  margin: 0 !important;
  padding: 5px;
}
.slider-wrapper .slider .slick-dots button {
  width: 24px;
  height: 24px;
  display: block;
  padding: 1.5px 0 !important;
  border-radius: 24px;
  opacity: 0.6;
  background-color: white;
}
.slider-wrapper .slider .slick-dots li.slick-active button {
  margin: 0 !important;
  opacity: 1;
  background-color: white;
}

.arrow-work-previous {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 9999;
  background-image: url("../img/icons/ic-chevron-left.svg");
  background-color: transparent;
}

.arrow-work-next {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9999;
  background-image: url("../img/icons/ic-chevron-right.svg");
  background-color: transparent;
}

.modal-content {
  background-color: #fefefe;
  width: 100%;
  /* Could be more or less, depending on screen size */
  height: 100%;
}
@media screen and (min-width: 1024px) {
  .modal-content {
    width: 80%;
    border: 1px solid #888;
    margin: 15% auto;
    /* 15% from the top and centered */
  }
}

.close {
  color: #aaa;
  float: right;
  padding: 10px;
  z-index: 10;
  font-size: 28px;
  font-weight: bold;
  right: 0;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.menu-modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(25, 40, 72, 0.4);
}

.menu-modal-content {
  display: flex;
  justify-content: center;
  background-color: #fefefe;
  width: 100%;
  /* Could be more or less, depending on screen size */
  height: max-content;
  padding-top: 20px;
}
.menu-modal-content .con {
  width: 95%;
}
.menu-modal-content .con img {
  width: 137px;
  height: 40px;
}
.menu-modal-content .con .mobile-menu ul > li {
  margin-left: 10px;
  margin-bottom: 30px;
}
.menu-modal-content .con .mobile-menu .py-6 .img-toolbox {
  width: 106px;
  height: 17px;
}
.menu-modal-content .con .mobile-menu .py-6 .img-nomad {
  width: 74px;
  height: 20px;
}
.menu-modal-content .con .mobile-menu .py-6 div {
  margin-left: 10px;
  margin-top: 25px;
}

.banner-wrapper #background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: relative;
  z-index: -1;
  margin-top: -112px;
}
.banner-wrapper .white-quotes, .banner-wrapper .green-quotes {
  display: none;
}
.banner-wrapper .sound-button {
  border: 1px solid #8390ac;
  padding: 8px;
  border-radius: 50%;
}

.intro-wrapper {
  position: relative;
  padding: 48px 16px;
}
@media screen and (min-width: 1024px) {
  .intro-wrapper {
    padding: 110px 205px;
  }
}
.intro-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../img/bg-shape@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.group-wrapper {
  padding: 48px 0;
  background-image: url("../img/bg-cyan@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1024px) {
  .group-wrapper {
    padding: 110px 0;
  }
}

.achievements-wrapper {
  padding: 48px 0;
  background-image: url("../img/bg-grey@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1024px) {
  .achievements-wrapper {
    padding: 110px 0;
  }
}
.achievements-wrapper img {
  object-fit: cover;
  object-position: center;
  max-height: 300px;
  min-height: 150px;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.roadmap-wrapper {
  padding: 48px 0;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .roadmap-wrapper {
    padding: 110px 0;
  }
}
.roadmap-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../img/shape-left@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 256px;
  height: 776px;
}
.roadmap-wrapper:after {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  bottom: 0;
  background-image: url("../img/shape-right@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 256px;
  height: 776px;
}
.roadmap-wrapper .roadmap-mobile {
  display: block;
}
@media screen and (min-width: 1024px) {
  .roadmap-wrapper .roadmap-mobile {
    display: none;
  }
}
.roadmap-wrapper .roadmap-desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .roadmap-wrapper .roadmap-desktop {
    display: block;
  }
}

.slider-home-wrapper {
  display: none;
  height: 400px;
  position: relative;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .slider-home-wrapper {
    display: block;
  }
}
.slider-home-wrapper .slider-home {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  top: 0;
  right: 0;
  position: absolute;
  width: calc(100vw + 100vh * 0.3);
}
.slider-home-wrapper .slider-home img {
  height: 400px !important;
  width: 100%;
  object-fit: cover;
}

.home-video-div {
  background-image: linear-gradient(to top, rgba(25, 40, 72, 0.7), rgba(1, 1, 2, 0.02) 56%, rgba(12, 20, 36, 0) 13%, rgba(25, 40, 72, 0.7));
}

.about-banner #background-video {
  width: 100vw;
  height: 50vh;
  object-fit: cover;
  position: relative;
  z-index: -1;
  margin-top: -112px;
}
@media screen and (max-width: 400px) {
  .about-banner p {
    font-size: 30px !important;
  }
}

.video-wrapper {
  position: relative;
}
.video-wrapper .video-control {
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  padding: 16px;
  background: #02ACDE;
  border-radius: 50%;
}
.video-wrapper #bottom-video {
  width: 100vw;
  object-fit: cover;
}

.about-intro-wrapper img {
  width: 100%;
  height: 260px;
  object-fit: cover;
}
@media screen and (min-width: 1024px) {
  .about-intro-wrapper img {
    width: 100%;
    height: 100%;
  }
}

.skills-wrapper {
  position: relative;
  background-image: url("../img/bg-grey@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 48px 16px;
}
@media screen and (min-width: 1024px) {
  .skills-wrapper {
    padding: 110px 99px;
  }
}

.img-wrapper {
  contain: content;
  height: 440px;
}
.img-wrapper img {
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.team-slider-wrapper {
  padding: 48px 0;
  position: relative;
  background: white;
}
@media screen and (min-width: 1024px) {
  .team-slider-wrapper {
    padding: 110px 100px 70px 100px;
  }
}
.team-slider-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../img/bg-shape@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.team-slider-wrapper .team-slider-phone-icon {
  transition: all 0.2s;
}
.team-slider-wrapper .team-slider-phone-icon:before {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("../../public/images/statics/icons/ic-phone.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.team-slider-wrapper .team-slider-phone-icon:hover:before {
  background-image: url("../../public/images/statics/icons/ic-phone-white.svg");
}
.team-slider-wrapper .slider img {
  width: 100%;
  aspect-ratio: 1/1.3;
}
.team-slider-wrapper .slider .team-slider-lay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, 0.65));
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  contain: content;
}
.team-slider-wrapper .slider .slick-dots {
  width: 80%;
  display: flex;
  padding-left: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  margin-left: 1rem;
  padding: 0px 0px;
  list-style-type: none;
  bottom: -65px;
  left: 0px;
}
.team-slider-wrapper .slider .slick-dots li {
  margin: 0 !important;
}
.team-slider-wrapper .slider .slick-dots button {
  width: 100%;
  display: block;
  height: 3px;
  padding: 1.5px 0 !important;
  border-radius: 1.5px;
  background-color: #8390ac;
}
.team-slider-wrapper .slider .slick-dots li.slick-active button {
  margin: 0 !important;
  background-color: #192848;
}
.team-slider-wrapper .slider-mobile .team-slider-lay {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 46%, rgba(0, 0, 0, 0.65));
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.14);
  contain: content;
}
.team-slider-wrapper .slider-mobile .team-slider-lay .team-slider-phone-icon:before {
  width: 14px;
  height: 14px;
}
.team-slider-wrapper .img-prev {
  display: block;
  padding: 12px;
  border: solid 1px rgba(131, 144, 172, 0.5);
  width: 48px;
  height: 48px;
  background: url("../img/icons/ic-arrow-left.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer;
}
.team-slider-wrapper .img-next {
  display: block;
  padding: 12px;
  border: solid 1px rgba(131, 144, 172, 0.5);
  width: 48px;
  height: 48px;
  background: url("../img/icons/ic-arrow-right.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer;
}

.group-banner-wrapper #background-video {
  width: 100vw;
  height: 50vh;
  object-fit: cover;
  position: relative;
  z-index: -1;
  margin-top: -112px;
}
@media screen and (max-width: 1024px) {
  .group-banner-wrapper p {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 550px) {
  .group-banner-wrapper p {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 375px) {
  .group-banner-wrapper p {
    font-size: 30px !important;
  }
}

.team-group-wrapper {
  padding: 48px 0;
}
@media screen and (min-width: 1024px) {
  .team-group-wrapper {
    padding: 110px 0;
  }
}
@media screen and (max-width: 1024px) {
  .team-group-wrapper #bt1, .team-group-wrapper #bt2 {
    border-bottom: solid 1px lightgray !important;
  }
}

.map-wrapper {
  position: relative;
  width: 100%;
  contain: content;
  height: calc(100vw * 1.1444);
}
@media screen and (min-width: 1024px) {
  .map-wrapper {
    height: 661px;
  }
}
.map-wrapper #mapImg {
  position: absolute;
  height: calc(100vw * 1.1444);
}
@media screen and (min-width: 1024px) {
  .map-wrapper #mapImg {
    height: 761px;
  }
}
.map-wrapper .background-map-desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .map-wrapper .background-map-desktop {
    display: block;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
}
.map-wrapper .background-map-mobile {
  display: block;
  width: 100%;
  z-index: -1;
}
@media screen and (min-width: 1024px) {
  .map-wrapper .background-map-mobile {
    display: none;
  }
}
.map-wrapper .countries > * {
  margin: 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  line-height: 22px;
}
.map-wrapper .countries :first-child {
  margin-top: 40px;
}
.map-wrapper .countries-wrapper {
  display: none;
}
@media screen and (min-width: 1024px) {
  .map-wrapper .countries-wrapper {
    display: block;
  }
}

.checkout-banner {
  padding: 48px 0;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .checkout-banner {
    padding: 110px 0;
  }
}
.checkout-banner:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../img/bg-shape@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.checkout-banner .container {
  max-width: 95% !important;
}
.checkout-banner .container li {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #192848;
  margin-bottom: 16px;
}
.checkout-banner .container li:before {
  content: "";
  display: block;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-image: url("../img/icons/ic-large-dot-primary.svg");
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 0px;
}
.checkout-banner .container .con {
  width: 32% !important;
}
@media screen and (max-width: 1024px) {
  .checkout-banner .container .con {
    margin: 0 auto;
    width: 80% !important;
  }
}
@media screen and (max-width: 768px) {
  .checkout-banner .container .con {
    width: 100% !important;
  }
}

.group-card-image {
  min-height: 102px;
  max-height: 102px;
}

.map-chip {
  border: 1px solid #ffffff80;
}
.map-chip.active {
  border: 1px solid #02ACDE;
  background-color: #02ACDE;
}

.toolbox-banner {
  padding: 38px 0px 48px 0px;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .toolbox-banner {
    padding: 112px 0px 171px 0px;
  }
}
.toolbox-banner .toolbox-paragraph > *:first-child {
  font-weight: 600;
}
.toolbox-banner .container .toolbox-desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .toolbox-banner .container .toolbox-desktop {
    display: block;
  }
}
.toolbox-banner .container .toolbox-mobile {
  display: block;
}
@media screen and (min-width: 1024px) {
  .toolbox-banner .container .toolbox-mobile {
    display: none;
  }
}
.toolbox-banner img {
  width: 100%;
  height: auto;
}
.toolbox-banner:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../img/bg-shapes-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.real-banner-warapper #background-video {
  width: 100vw;
  height: 50vh;
  object-fit: cover;
  position: relative;
  z-index: -1;
  margin-top: -112px;
}
@media screen and (max-width: 1024px) {
  .real-banner-warapper p {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 550px) {
  .real-banner-warapper p {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 375px) {
  .real-banner-warapper p {
    font-size: 30px !important;
  }
}

.work-wrapper {
  padding: 48px 0;
  background-image: url("../img/bg-grey@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (min-width: 1024px) {
  .work-wrapper {
    padding: 20px 0;
  }
}
.work-wrapper .work-container {
  margin-left: -1rem;
  margin-right: -1rem;
}
.work-wrapper img {
  cursor: pointer;
}
.work-wrapper .big-img {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .work-wrapper .big-img {
    width: 70%;
  }
}
.work-wrapper .big-img img {
  width: 100%;
  height: 210px;
  object-fit: cover;
}
@media screen and (min-width: 1024px) {
  .work-wrapper .big-img img {
    width: 100%;
    height: 392px;
  }
}
.work-wrapper .work-grid-text {
  width: calc(100% - 2rem);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
}
.work-wrapper .small-img {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .work-wrapper .small-img {
    width: 30%;
  }
}
.work-wrapper .small-img img {
  width: 100%;
  height: 210px;
  object-fit: cover;
}
@media screen and (min-width: 1024px) {
  .work-wrapper .small-img img {
    width: 100%;
    height: 392px;
  }
}
.work-wrapper .full-img {
  width: 100%;
}
.work-wrapper .full-img img {
  width: 100%;
  height: 210px;
  object-fit: cover;
}
@media screen and (min-width: 1024px) {
  .work-wrapper .full-img img {
    width: 100%;
    height: 392px;
  }
}

.work-modal .swiper-slide {
  display: flex;
  align-items: center;
}

.contact-wrapper img {
  width: 100%;
  height: 100%;
}

.contact-form-container {
  padding: 32px 16px;
}
@media screen and (min-width: 1024px) {
  .contact-form-container {
    padding: 90px 101px 91px 106px;
  }
}

.sidebar-desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .sidebar-desktop {
    display: block;
  }
}
.sidebar-desktop .sidebar li {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  color: #8390ac;
  margin-top: 26px;
  text-transform: uppercase;
}
.sidebar-desktop .sidebar li:before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("../img/icons/ic-dot.svg");
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 40px;
}
.sidebar-desktop .sidebar li:before svg {
  fill: blue;
}
.sidebar-desktop .sidebar li.active {
  font-weight: 600;
  color: #192848;
}
.sidebar-desktop .sidebar li.active:before {
  background-image: url("../img/icons/ic-dot-active.svg");
}
.sidebar-desktop .sidebar li.active:before svg {
  fill: red;
}

.sidebar-mobile {
  display: block;
}
@media screen and (min-width: 1024px) {
  .sidebar-mobile {
    display: none;
  }
}
.sidebar-mobile .slick-slide {
  height: 0px !important;
}
.sidebar-mobile .slick-slide.slick-active {
  height: 100% !important;
}
.sidebar-mobile .slick-dots {
  position: static;
}