.form-group {
  position: relative;
}

.form-control:focus {
  border: solid 2px #02acde !important;
}

.form-group>label {
    top: 0px;
    left: 20px;
    position: absolute;
    background-color: white;
    padding: 0px 5px 0px 5px;
    visibility: hidden;
    font-size: 1.1em;
    transition: 0.2s;
    pointer-events:none;
}

.form-group.label-animate>label {
    top: -10px !important;
    left: 20px !important;
    font-size: 0.9em;
    visibility: visible;
    color: #02acde;
}

input {
  width: 100%;
  height: 100%;
  border: solid 1px #8390ac;
  border-radius: 4px;
  padding: 12px 12px 12px 16px;
  outline: none;
}


textarea {
  width: 100%;
  height: 100%;
  border: solid 1px #8390ac;
  border-radius: 4px;
  padding: 12px 12px 12px 16px;
  outline: none;
}

textarea:focus {
  border: solid 2px #02acde;
}

.input-group{
    margin-bottom: 20px;
}