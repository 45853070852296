.banner-wrapper {
    #background-video {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        position: relative;
        z-index: -1;
        margin-top: -112px;
    }
    .white-quotes, .green-quotes {
        display: none;
    }
    .sound-button {
        border: 1px solid $grey;
        padding: 8px;
        border-radius: 50%;
    }
}
.intro-wrapper {
    position: relative;
    padding: 48px 16px;
    @media screen and (min-width:1024px) {
        padding: 110px 205px;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('../img/bg-shape@2x.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left top;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
.group-wrapper {
    padding: 48px 0;
    background-image: url('../img/bg-cyan@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (min-width: 1024px) {
        padding: 110px 0;
    }
}

.achievements-wrapper {
    padding: 48px 0;
    @media screen and (min-width:1024px) {
        padding: 110px 0;
    }
    background-image: url('../img/bg-grey@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    img {
        object-fit: cover; 
        object-position: center;
        max-height: 300px; 
        min-height: 150px;
        height: 100%; 
        width: 100%;
        cursor: pointer;
    }
}

.roadmap-wrapper {
    padding: 48px 0;
    @media screen and (min-width: 1024px) {
        padding: 110px 0;
    }
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('../img/shape-left@2x.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 256px;
        height: 776px;
        
    }
    &:after {
        content: '';
        position: absolute;
        display: block;
        right: 0;
        bottom: 0;
        background-image: url('../img/shape-right@2x.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 256px;
        height: 776px;
    }

    .roadmap-mobile {
        display: block;
        @media screen and (min-width: 1024px) {
            display: none;
        }
    }
    .roadmap-desktop {
        display: none;
        @media screen and (min-width: 1024px) {
            display: block;
        }
    }
}

.slider-home-wrapper {
    display: none;
    height: 400px;
    position: relative;
    min-width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    @media screen and (min-width: 1024px) {
        display: block;
    }
    .slider-home {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        top: 0;
        right: 0;
        position: absolute;
        width: calc(100vw + 100vh * 0.3);
        img {
            height: 400px !important;
            width: 100%;
            object-fit: cover;
        }
    }
}

.home-video-div {
    background-image: linear-gradient(to top, rgba(25, 40, 72, 0.7), rgba(1, 1, 2, 0.02) 56%, rgba(12, 20, 36, 0) 13%, rgba(25, 40, 72, 0.7));
}
