.real-banner-warapper {
    #background-video {
        width: 100vw;
        height: 50vh;
        object-fit: cover;
        position: relative;
        z-index: -1;
        margin-top: -112px;
        }
        p {
                @media screen and (max-width: 1024px) {
                        font-size:50px !important;
                }

                @media screen and (max-width: 550px) {
                        font-size:35px !important;
                }

                @media screen and (max-width: 375px) {
                        font-size:30px !important;
                }
        }
}

.work-wrapper {
    padding: 48px 0;
    @media screen and (min-width:1024px) {
        padding: 20px 0;
    }

    .work-container {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    background-image: url('../img/bg-grey@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    img {
        cursor: pointer;
    }
    
    .big-img {
        @media screen and (min-width:1024px) {
            width: 70%;
        }
        width: 100%;
        img {
            width: 100%;
            height: 210px;
            object-fit: cover;
            @media screen and (min-width: 1024px) {
                width: 100%;
                height: 392px;    
            }
        }
        
    }

    .work-grid-text {
        width: calc(100% - 2rem);
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
    }

    .small-img {
        @media screen and (min-width:1024px) {
            width: 30%;
        }
        width: 100%;
        img {
            width: 100%;
            height: 210px;
            object-fit: cover;
            @media screen and (min-width: 1024px) {
                width: 100%;
                height: 392px;    
            }
        }
    }

    .full-img {
        width: 100%;
        img {
            width: 100%;
            height: 210px;
            object-fit: cover;
            @media screen and (min-width: 1024px) {
                width: 100%;
                height: 392px;    
            }           
        }
    }
}

.work-modal {
    .swiper-slide {
        display: flex;
        align-items: center; 
    }
}