.sidebar-desktop {
    display: none;
    @media screen and (min-width: 1024px) {
        display: block;
    }
    .sidebar {
        li {
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            font-size: 18px;
            color: $secondary-variant;
            margin-top: 26px;
            text-transform: uppercase;
            &:before {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                background-image: url('../img/icons/ic-dot.svg');
                background-size: contain;
                background-repeat: no-repeat;
                padding-right: 40px;
                svg {
                    // fill: #8390ac;
                    fill: blue;
                }
            }
            &.active {
                font-weight: 600;
                color: $secondary;
                &:before {
                    svg {
                        // fill: #192848;
                        fill: red;
                    }
                    background-image: url('../img/icons/ic-dot-active.svg');
                }
            }
        }
    }
}
.sidebar-mobile {
    display: block;
    @media screen and (min-width: 1024px) {
        display: none;
    }
    .slick-slide {
        height: 0px !important;
    }
    .slick-slide.slick-active {
        height: 100% !important;  
    }

    .slick-dots {
        position: static;
    }
}