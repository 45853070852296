.toolbox-banner {
    padding: 38px 0px 48px 0px;
    @media screen and (min-width:1024px) {
        padding: 112px 0px 171px 0px ;    
    }

    .toolbox-paragraph {
        & > *:first-child {
            font-weight: 600;
        }
    }

    .container {
        .toolbox-desktop {
            display: none;
            @media screen and (min-width:1024px) {
                display: block;
            }
        }

        .toolbox-mobile {
            display: block;
            @media screen and (min-width:1024px) {
                display: none;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
    }
    position: relative;
    &:before {
        @media screen and (min-width: 1024px) {
            
        }
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url('../img/bg-shapes-1.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        z-index: -1;
    }


    
}