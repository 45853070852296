.work-modal {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(25, 40, 72,0.4);
    .container {
      max-height: 100%;
      @media screen and (max-width:768px) {
        max-width: 100% !important;
        padding:0 !important;
      }
      
      .swiper {
        @media screen and (max-width:768px) {
          overflow: inherit;
        }
      }

      .swiper .swiper-slide img {
        object-fit: cover;
        @media screen and (max-width:768px) {
          height: 280px !important;
        }
      }

      .swiper-button-prev, .swiper-button-next {
        color:white;
      }

      .swiper-button-next:after, .swiper-button-prev:after {
        font-size: 20px;
        font-weight: bold;
      }

      .swiper-pagination-bullet-active, .swiper-pagination-bullet {
        opacity: 1;
        background: white !important;
      }

      .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 7px;
      }

      .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 20px;
      }
      
    }
}


.slider-wrapper {
  position: relative;
  height: inherit !important;

  .slider {

    margin-bottom: 0px !important;
    img {
      height: 100% !important;
    }
    .slick-dots {
        position: absolute;
        display: flex;
        bottom: 20px;
        align-items: center;
        justify-content: center;
        li {
            margin: 0 !important;
            padding: 5px;
        }

        button {
            width: 24px;
            height: 24px;            
            display: block;
            padding: 1.5px 0 !important;
            border-radius: 24px;
            opacity: 0.6;
            background-color: white;
        }
        
        li.slick-active button {
            margin: 0 !important;
            opacity: 1;
            background-color: white;
        }
      }
    }
}

.arrow-work-previous {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 9999;
  background-image: url('../img/icons/ic-chevron-left.svg');
  background-color: transparent;
}

.arrow-work-next {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9999;
  background-image: url('../img/icons/ic-chevron-right.svg');
  background-color: transparent;
}

.modal-content {
    background-color: #fefefe;
    width: 100%;/* Could be more or less, depending on screen size */
    height: 100%;
    @media screen and (min-width: 1024px) {
        width: 80%;    
        border: 1px solid #888;
        margin: 15% auto; /* 15% from the top and centered */
    }
  }

  .close {
    color: #aaa;
    float: right;    
    padding: 10px;
    z-index: 10;
    font-size: 28px;
    font-weight: bold;
    right: 0;
  }

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}